schema {
    query: Query
    mutation: Mutation
}

type Query {
    """
    fetchCurrentUser returns user information for the currently logged in User

    If no user is currently logged in, the http request will return a 403 error, no graphQL body will be returned.
    """
    fetchCurrentUser: User!

    """
    fetchHealthPlanPackage returns a specific HealthPlanPackage by id

    If a package with the given ID cannot be found, this query returns undefined
    CMS users cannot fetch a DRAFT HealthPlanPackage

    Errors:
    - ForbiddenError:
        - A state user from a different state called this.
        - A CMSUser attempted to fetch a DRAFT HealthPlanPackage
    """
    fetchHealthPlanPackage(
        input: FetchHealthPlanPackageInput!
    ): FetchHealthPlanPackagePayload!

    """
    indexHealthPlanPackages returns all of the HealthPlanPackages the current user can see.

    StateUsers can find all the packages for their state
    CMSUsers can find all the packages that do not have the DRAFT status
    """
    indexHealthPlanPackages: IndexHealthPlanPackagesPayload!

    """
    indexContracts returns all of the contracts the current user can see.

    StateUsers can find all the contracts for their state
    CMSUsers can find all the contracts that do not have the DRAFT status
    """
    indexContracts: IndexContractsPayload!
    """
    indexUsers returns all of the Users in the system.

    It can only be called by an AdminUser

    Errors: ForbiddenError: A non-AdminUser called this
    """
    indexUsers: IndexUsersPayload!

    """
    indexRates returns an array of rates with their revisions and related contracts
    Only rates with at least one submitted revision are returned
    indexRates can be called by CMS and admin users

    Errors:
    - ForbiddenError: User must be a CMS or Admin type user
    - NotFoundError: No rates with at least one submitted revision were found
    """
    indexRates(input: IndexRatesInput): IndexRatesPayload!
    """
    fetchRate returns a rate with its revisions, including contract revisions
    for a given rate's ID

    It can be called by CMS or State users

    Errors:
    - ForbiddenError: This API is not available due to feature flags.
    - NotFoundError: rate for rate.ID not found in database
    """
    fetchRate(input: FetchRateInput!): FetchRatePayload!

    """
    fetchContract returns a single contract, linked to revisions and related rates
    given a contractID

    It can be called by CMS or State users

    Errors:
    - ForbiddenError: A State user requests a contract that belongs to another state
    - NotFoundError: contract for contractID not found in database
    """
    fetchContract(input: FetchContractInput!): FetchContractPayload!

    """
    fetchMcReviewSettings returns settings for the MC review app

    stateAssignments: List of all States in the system with their assigned CMS users.

    Errors:
    - ForbiddenError: A State user requests a contract that belongs to another state
    """
    fetchMcReviewSettings: FetchMcReviewSettingsPayload!
}

type Mutation {
    """
    createHealthPlanPackage creates a new HealthPlanPackage.

    The new HealthPlanPackage is created with a single HealthPlanRevision with
    the information specified in the input parameters. The created package will have
    the DRAFT status. The stateCode of the package will be set to the state the
    user that calls this mutation is from.

    This can only be called by StateUsers

    Errors:
    - ForbiddenError: A CMSUser calls this mutation
    - UserInputError: ProgramID not found in this state's programs
    """
    createHealthPlanPackage(
        input: CreateHealthPlanPackageInput!
    ): CreateHealthPlanPackagePayload!

    """
    createContract creates a new Contract.

    The new Contract is created with a single contractRevision with
    the information specified in the input parameters. The created contract will have
    the DRAFT status. The stateCode of the contract will be set to the state the
    user that calls this mutation is from.

    This can only be called by StateUsers

    Errors:
    - ForbiddenError: A CMSUser calls this mutation
    - UserInputError: ProgramID not found in this state's programs
    """
    createContract(
        input: CreateContractInput!
    ): CreateContractPayload!

    """
    updateHealthPlanFormData updates a single package with the current
    state of the form encoded as a protobuf.

    The package must be either in the DRAFT or UNLOCKED state.
    Only a state user from the state this package is attached to can call this mutation

    There are some fields in the healthPlanFormData type that must not be modified
    by this mutation. They are set on the initial submission and are only changed by the server:
    - id
    - stateCode
    - stateNumber
    - createdAt
    - updatedAt

    Errors:
    - ForbiddenError:
        - A CMSUser called this
        - A state user from a different state called this.
    - UserInputError:
        - The healthPlanFormData proto did not decode correctly
        - The healthPlanFormData decodes to a LockedHealthPlanFormData
        - The package is in the LOCKED or RESUBMITTED status
        - A package cannot be found with the given `pkgID`
        - The healthPlanFormData includes changes to any of the fields that are fixed on submission
    """
    updateHealthPlanFormData(
        input: UpdateHealthPlanFormDataInput!
    ): UpdateHealthPlanFormDataPayload!

    """
    updateContract can be used to update fields on the contract
    as opposed to an individual revision
    """
    updateContract(input: UpdateContractInput!): UpdateContractPayload!

    """
    updateContractDraftRevision updates a contract's draft revision with the current
    state of the form.

    The contract must be either in the DRAFT or UNLOCKED state.
    Only a state user from the state this package is attached to can call this mutation

    Errors:
    - ForbiddenError:
    - A CMSUser called this
    - A state user from a different state called this.
    UserInputError:
    - The package is in the LOCKED or RESUBMITTED status
    - A package cannot be found with the given `contractID`
    """
    updateContractDraftRevision(
        input: UpdateContractDraftRevisionInput!
    ): UpdateContractDraftRevisionPayload!

    """
    updateDraftContractRates updates the rates associated with a DRAFT contract revision.
    It takes in an array of rate inputs, which can be new rates, updated rates, or linked rates.
    The API will make the necessary data changes to make the data match the update, including
    unlinking removed rates

    Errors:
    - ForbiddenError:
        - A CMSUser called this
        - A state user from a different state called this.
    - UserInputError:
        - any of the linked or update rates' ids don't exist
        - attempts to update the data of a nibling rate
        - attempts to simply link a child rate
        - The package is in the LOCKED or RESUBMITTED status
        - A package cannot be found with the given `pkgID`
    """
    updateDraftContractRates(
        input: UpdateDraftContractRatesInput!
    ): UpdateDraftContractRatesPayload!

    """
    submitHealthPlanPackage submits the given package for review by CMS.

    This can only be called by a StateUser from the state the package is for.
    The package must be either in DRAFT or UNLOCKED state to be submitted
    On resubmit the `submittedReason` field must be filled out.
    The submission must be complete for this mutation to succeed. All required fields
    in the healthPlanFormData must be filled out correctly.
    Email notifications will be sent to all the relevant parties

    Errors:
    - ForbiddenError:
        - A CMSUser called this
        - A state user from a different state called this.
    - UserInputError
        - A package cannot be found with the given `pkgID`
        - The healthPlanFormData does not have all required field filled out
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - Postgres returns error when attempting to find a package
            - Postgres returns error when attempting to update a package
            - Attempt to find state programs from json file returns an error
        - INVALID_PACKAGE_STATUS
            - Attempted to submit a package in the SUBMITTED or RESUBMITTED state
        - PROTO_DECODE_ERROR
            - Failed to decode draft proto
        - EMAIL_ERROR
            - Sending state or CMS email failed.
    """
    submitHealthPlanPackage(
        input: SubmitHealthPlanPackageInput!
    ): SubmitHealthPlanPackagePayload!

    """
    unlockHealthPlanPackage returns a submitted package to the state for additional
    edits.

    This can only be called by a CMSUser.
    The package must be in the SUBMITTED or RESUBMITTED state to be unlocked.
    Email notifications will be sent to all the relevant parties

    Errors:
    - ForbiddenError:
        - A non CMSuser called this
    - UserInputError
        - A package cannot be found with the given `pkgID`
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - Postgres returns error when attempting to finding a package
            - Postgres returns error when attempting to update a package
        - INVALID_PACKAGE_STATUS
            - Attempted to unlock a package in the DRAFT or UNLOCKED state
        - PROTO_DECODE_ERROR
            - Failed to decode draft proto
        - EMAIL_ERROR
            - Sending state or CMS email failed.
    """
    unlockHealthPlanPackage(
        input: UnlockHealthPlanPackageInput!
    ): UnlockHealthPlanPackagePayload!

    """
    unlockHealthPlanPackage returns a submitted package to the state for additional
    edits.

    This can only be called by a CMSUser.
    The package must be in the SUBMITTED or RESUBMITTED state to be unlocked.
    Email notifications will be sent to all the relevant parties

    Errors:
    - ForbiddenError:
        - A non CMSuser called this
    - UserInputError
        - A package cannot be found with the given `pkgID`
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - Postgres returns error when attempting to finding a package
            - Postgres returns error when attempting to update a package
        - INVALID_PACKAGE_STATUS
            - Attempted to unlock a package in the DRAFT or UNLOCKED state
        - PROTO_DECODE_ERROR
            - Failed to decode draft proto
        - EMAIL_ERROR
            - Sending state or CMS email failed.
    """
    unlockContract(
        input: UnlockContractInput!
    ): UnlockContractPayload!


    """
    updateDivisionAssignment updates CMS user division assignment.

    This can only be called by an AdminUser.
    The cmsUserID must be a CMSUser's id, not a state user

    Errors:
    - ForbiddenError:
        - A non AdminUser called this
    - UserInputError
        - cmsUserID was not a CMSUser's ID
        - stateCodes included an invalid state code
    """
    updateDivisionAssignment(input: UpdateDivisionAssignmentInput!): UpdateCMSUserPayload!

    """
    updateStateAssignment updates an individual CMSUser state assignments.

    This can only be called by an AdminUser or a DMCO CMS user.
    The cmsUserID must be a CMSUser's id, not a state user

    Errors:
    - ForbiddenError:
    - A non AdminUser called this
    - A non DMCO CMS user called this
    - UserInputError
    - cmsUserID was not a CMSUser's ID
    - stateCodes included an invalid state code
    """
    updateStateAssignment(input: UpdateStateAssignmentInput!): UpdateCMSUserPayload!

    """
    updateStateAssignmentByState updates a group of CMSUsers to be assigned to a given state.

    This can only be called by an AdminUser or a DMCO CMS user.
    The cmsUserID must be a CMSUser's id, not a state user

    Errors:
    - ForbiddenError:
    - A non AdminUser called this
    - A non DMCO CMS user called this
    - UserInputError
    - cmsUserID was not a CMSUser's ID
    - stateCodes included an invalid state code
    """
    updateStateAssignmentsByState(input: UpdateStateAssignmentsByStateInput!): UpdateStateAssignmentsByStatePayload!

    """
    updateEmailSettings updates the email settings the MC Review app
    """
    updateEmailSettings(input: UpdateEmailSettingsInput!): UpdateEmailSettingsPayload!

    """
    createContractQuestion creates a new question for the given Contract
    A CMS User can add text to a note field and append a document to their question
    They can also specify a due date, and specify rate IDs that are associated with the question

    This can only be called by a CMSUser

    Errors:
    - ForbiddenError:
        - A non CMSUser called this
        - A CMS user with unassigned division
    - UserInputError
        - A package cannot be found with the given `contractID`
        - The contract is in the DRAFT state
        - The due date is in the past
        - The rateIDs are not associated with the package
    """
    createContractQuestion(input: CreateContractQuestionInput!): CreateContractQuestionPayload!

    #    """
    #    updateQuestion updates a Question for the given HealthPlanPackage
    #    Only the due date can be changed
    #
    #    This can only be called by a CMSUser
    #
    #    Errors:
    #    - ForbiddenError:
    #        - A non CMSUser called this
    #    - UserInputError
    #        - A Question cannot be found given `questionID`
    #        - The due date is in the past
    #    """
    #    updateQuestion(input: UpdateQuestionInput!): UpdateQuestionPayload!

    """
    createContractQuestionResponse creates a new response for the given question
    A State User can add text to a note field and append a document to their response

    This can only be called by a StateUser

    Errors:
    - ForbiddenError:
        - A non StateUser called this
    - UserInputError
        - A Question cannot be found given `questionID`
    """
    createContractQuestionResponse(
        input: CreateQuestionResponseInput!
    ): CreateContractQuestionResponsePayload!

    """
    createRateQuestion creates a new Question for the given rate
    A CMS User can add text to a note field and append a document to their question
    They can also specify a due date, and specify rate IDs that are associated with the question

    This can only be called by a CMSUser

    Errors:
    - ForbiddenError:
    - A non CMSUser called this
    - A CMS user with unassigned division
    - UserInputError
    - A rate cannot be found with the given `rateID`
    - The rate is in the DRAFT state
    - The due date is in the past
    """
    createRateQuestion(
        input: CreateRateQuestionInput!
    ): CreateRateQuestionPayload!

    createRateQuestionResponse(
        input: CreateQuestionResponseInput!
    ): CreateRateQuestionResponsePayload!

    """
    createAPIKey creates a valid API key for the logged in user, valid for 90 days
    """
    createAPIKey: CreateAPIKeyPayload!

    """
    unlockRate returns a submitted package to the state for additional edits.

    This can only be called by a CMSUser.
    The rate must be in the SUBMITTED or RESUBMITTED state to be unlocked.
    Email notifications will be sent to all the relevant parties

    Errors:
    - ForbiddenError:
        - A non CMSuser called this
    - UserInputError
        - A rate cannot be found with the given `rateID`
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - Postgres returns error when attempting to finding rate
            - Postgres returns error when attempting to update rate
        - INVALID_PACKAGE_STATUS
            - Attempted to unlock a rate in the DRAFT or UNLOCKED state
        - EMAIL_ERROR
            - Sending state or CMS email failed.
    """
    unlockRate(input: UnlockRateInput!): UnlockRatePayload!

    """
    submitRate will submit an unlocked rate and return the submitted rate data.

    This can only be called by a StateUser.
    The rate must be in the DRAFT or UNLOCKED state to be submitted.

    Errors:
    - ForbiddenError:
        - A non State user called this
    - UserInputError
        - A rate cannot be found with the given `rateID`
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - Postgres returns error when attempting to finding rate
            - Postgres returns error when attempting to update rate
            - Postgres returns error when attempting to submit rate
            - Postgres returns error when both rateID or rateRevisionID are blank.
            - Postgres returns error when current rate revision to submit cannot be found
        - INVALID_PACKAGE_STATUS
            - Attempted to unlock a rate in the DRAFT or UNLOCKED state
    """
    submitRate(input: SubmitRateInput!): SubmitRatePayload!

    """
    submitContract submits the given package for review by CMS.

    This can only be called by a StateUser from the state the package is for.
    The package must be either in DRAFT or UNLOCKED state to be submitted
    On resubmit the `submittedReason` field must be filled out.
    The submission must be complete for this mutation to succeed. All required fields
    in the ContractFormData and RateFormData must be filled out correctly.
    Email notifications will be sent to all the relevant parties

    Errors:
    - ForbiddenError:
        - A CMSUser called this
        - A state user from a different state called this.
    - UserInputError
        - A package cannot be found with the given `pkgID`
        - The contract and or rates do not have all required fields filled out
        - INVALID_PACKAGE_STATUS
            - Attempted to submit a package in the SUBMITTED or RESUBMITTED state
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - Postgres returns error when attempting to find a package
            - Postgres returns error when attempting to update a package
            - Attempt to find state programs from json file returns an error
        - EMAIL_ERROR

            - Sending state or CMS email failed.
    """
    submitContract(
        input: SubmitContractInput!
    ): SubmitContractPayload!

 """
    withdrawAndReplaceRedundantRate is an Admin only action to address bookkeeping errors with rate

    Prior to the release of linked rates, states would submit contracts with the same rate
    resulting in duplicate rates in the dashboard that were otherwise identical.
    This API addresses the problem by replacing thr duplicate redundant rates with a linked rate.

    Errors:
    - ForbiddenError:
        - A non Admin user called this
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - A contract or rate cannot be found by id
            - A invalid update reason was submitted
            - The contract or target rates are unlocked. This API only works on submitted resources.
            - The withdrawnRate points to a linked rate. Only child rates can be withdrawn
    """
    withdrawAndReplaceRedundantRate(
        input: withdrawAndReplaceRedundantRateInput!
    ): withdrawAndReplaceRedundantRatePayload

    """
    approveContract is a CMS user only action 

    Errors:
    - ForbiddenError:
        - A non CMS/CMS Approver user called this
    - UserInputError:
        - A contract is not in the correct submission status: SUBMITTED or RESUBMITTED
        - A contract is not in the correct review status: UNDER_REVIEW
    - INTERNAL_SERVER_ERROR
        - DB_ERROR
            - A contract cannot be found by id
    """
    approveContract(
        input: ApproveContractInput!
    ): ApproveContractPayload

    """
    withdrawRate is CMS user only action to withdraw a rate from review

    Errors:
    - ForbiddenError:
    - A non CMS/CMS Approver user called this
    - UserInputError:
    - A contract is not in the correct submission status: SUBMITTED or RESUBMITTED
    - A contract is not in the correct review status: UNDER_REVIEW
    - INTERNAL_SERVER_ERROR
    - DB_ERROR
    - A contract cannot be found by id
    """
    withdrawRate(
        input: WithdrawRateInput!
    ): WithdrawRatePayload
}

input CreateHealthPlanPackageInput {
    """
    The large overarching population of people that the program covers.
    Options are MEDICAID, CHIP, MEDICAID_AND_CHIP
    """
    populationCovered: PopulationCoveredType!
    "An array of managed care program IDs this package covers"
    programIDs: [ID!]!
    "Whether or not this contract is risk based"
    riskBasedContract: Boolean
    """
    The submission type of this package
    Options are CONTRACT_ONLY and CONTRACT_AND_RATES
    """
    submissionType: SubmissionType!
    "User description of the reason for the submission"
    submissionDescription: String!
    """
    Type of contract the state is submitting
    Options are: BASE, AMENDMENT
    """
    contractType: ContractType!
}

type CreateHealthPlanPackagePayload {
    "The newly created HealthPlanPackage"
    pkg: HealthPlanPackage!
}

input CreateContractInput {
    """
    The large overarching population of people that the program covers.
    Options are MEDICAID, CHIP, MEDICAID_AND_CHIP
    """
    populationCovered: PopulationCovered!
    "An array of managed care program IDs this package covers"
    programIDs: [String!]!
    "Whether or not this contract is risk based"
    riskBasedContract: Boolean
    """
    The submission type of this package
    Options are CONTRACT_ONLY and CONTRACT_AND_RATES
    """
    submissionType: SubmissionType!
    "User description of the reason for the submission"
    submissionDescription: String!
    """
    Type of contract the state is submitting
    Options are: BASE, AMENDMENT
    """
    contractType: ContractType!
}

type CreateContractPayload {
    contract: Contract!
}

type ApproveContractPayload {
    contract: Contract!
}

input ApproveContractInput {
    "The ID of the contract to approve"
    contractID: ID!
    "Manually entered date for when the approval was released to the state"
    dateApprovalReleasedToState: Date!
}

type WithdrawRatePayload {
    rate: Rate!
}

input WithdrawRateInput {
    rateID: ID!
    updatedReason: String!
}

input FetchHealthPlanPackageInput {
    "The ID of the package to fetch"
    pkgID: ID!
}

type FetchHealthPlanPackagePayload {
    "A single HealthPlanPackage"
    pkg: HealthPlanPackage!
}

type HealthPlanPackageEdge {
    node: HealthPlanPackage!
}

type ContractEdge {
    node: Contract!
}

type IndexHealthPlanPackagesPayload {
    totalCount: Int
    edges: [HealthPlanPackageEdge!]!
}

type IndexContractsPayload {
    totalCount: Int
    edges: [ContractEdge!]!
}

type IndexContractQuestionsPayload {
    "Questions for a given submission that were asked by DMCO within CMS"
    DMCOQuestions: ContractQuestionList!
    "Questions for a given submission that were asked by DMCP within CMS"
    DMCPQuestions: ContractQuestionList!
    "Questions for a given submission that were asked by OACT within CMS"
    OACTQuestions: ContractQuestionList!
}

type IndexRateQuestionsPayload {
    "Questions for a given rate that were asked by DMCO within CMS"
    DMCOQuestions: RateQuestionList!
    "Questions for a given rate that were asked by DMCP within CMS"
    DMCPQuestions: RateQuestionList!
    "Questions for a given rate that were asked by OACT within CMS"
    OACTQuestions: RateQuestionList!
}

type ContractQuestionList {
    totalCount: Int
    edges: [ContractQuestionEdge!]!
}

type RateQuestionList {
    totalCount: Int
    edges: [RateQuestionEdge!]!
}

type ContractQuestionEdge {
    node: ContractQuestion!
}

type RateQuestionEdge {
    node: RateQuestion!
}

input DocumentInput {
    "The name of the document"
    name: String!
    "The S3 URL of the document"
    s3URL: String!
    "The url used for downloading a file from S3"
    downloadURL: String
}

input CreateContractQuestionInput {
    "The ID of the package for which to create a question"
    contractID: ID!
    "A list of documents to attach to the question"
    documents: [DocumentInput!]! # should be Generic Document with sha256
    "A note to attach to the question"
    noteText: String
    "The date the answer to the question is due"
    dueDate: Date
    "The rate IDs associated with the question"
    rateIDs: [String!]
}

type CreateContractQuestionPayload {
    "The newly created Question"
    question: ContractQuestion!
}

input CreateRateQuestionInput {
    "The ID of the rate for which to create a question"
    rateID: ID!
    "A list of documents to attach to the question"
    documents: [DocumentInput!]! # should be Generic Document with sha256
    "A note to attach to the question"
    noteText: String
    "The date the answer to the question is due"
    dueDate: Date
}

type CreateRateQuestionPayload {
    "The newly created rate question"
    question: RateQuestion!
}

#input UpdateQuestionInput {
#    "The ID of the question to update"
#    questionID: ID!
#    "The new due date for the question"
#    dueDate: Date
#}

#type UpdateQuestionPayload {
#    "The updated Question"
#    question: Question!
#}

"""
Generic input type for both Contract and Rate Question.
"""
input CreateQuestionResponseInput {
    "The ID of the question for which to create a response"
    questionID: ID!
    "A list of documents to attach to the response"
    documents: [DocumentInput!]! # should be Generic Document with sha256
    "A note to attach to the response"
    noteText: String
}

type CreateContractQuestionResponsePayload {
    "Contract Question with newly created response"
    question: ContractQuestion!
}

type CreateRateQuestionResponsePayload {
    "Rate Question with newly created response"
    question: RateQuestion!
}

type UserEdge {
    node: User!
}

type IndexUsersPayload {
    totalCount: Int
    edges: [UserEdge!]!
}

input SubmitHealthPlanPackageInput {
    pkgID: ID!
    "User given reason this package was re-submitted. Left blank on initial submit."
    submittedReason: String
}

type SubmitHealthPlanPackagePayload {
    pkg: HealthPlanPackage!
}

input SubmitContractInput {
    contractID: ID!
    "User given reason this package was re-submitted. Left blank on initial submit."
    submittedReason: String
}

type SubmitContractPayload {
    contract: Contract!
}

input UnlockHealthPlanPackageInput {
    pkgID: ID!
    "User given reason this package was unlocked"
    unlockedReason: String!
}

input UnlockContractInput {
    contractID: ID!
    "User given reason this contract was unlocked"
    unlockedReason: String!
}

type UnlockHealthPlanPackagePayload {
    pkg: HealthPlanPackage!
}

type UnlockContractPayload {
    contract: UnlockedContract!
}

input UpdateHealthPlanFormDataInput {
    "ID of the package to be updated, must be DRAFT or UNLOCKED"
    pkgID: ID!
    """
    base64 encoded HealthPlanFormData protobuf. This contains all the data
    from the health plan pacakge form that the state user fills out and submits.
    Its schema can be found in health_plan_form_data.proto
    """
    healthPlanFormData: String!
}

type UpdateHealthPlanFormDataPayload {
    pkg: HealthPlanPackage!
}

enum Division {
    DMCO
    DMCP
    OACT
}

input UpdateDivisionAssignmentInput {
    cmsUserID: ID!
    "divisionAssignment is one of the CMS divisions to which a user is assigned: 'DMCO', 'DMCP', or 'OACT'"
    divisionAssignment: Division
}

input UpdateStateAssignmentInput {
    cmsUserID: ID!
    "stateAssignments is an array of stateCodes (e.g. ['CA', 'NM', 'TN'])"
    stateAssignments: [String!]!
}

input UpdateStateAssignmentsByStateInput {
    stateCode: ID!
    "assignedUsers is an array of userIDs (uuids)"
    assignedUsers: [String!]!
}

type UpdateStateAssignmentsByStatePayload {
    stateCode: ID!
    assignedUsers: [CMSUsersUnion!]!
}

type UpdateCMSUserPayload {
    user: CMSUsersUnion!
}

input UpdateContractDraftRevisionInput {
    contractID: ID!
    "date the contracts draft revision was last updated at"
    lastSeenUpdatedAt: DateTime!
    formData: ContractDraftRevisionFormDataInput!
}

type UpdateContractDraftRevisionPayload {
    contract: Contract!
}

input UpdateContractInput {
    id: ID!
    mccrsID: String
}

type UpdateContractPayload {
    pkg: HealthPlanPackage!
}

type CreateAPIKeyPayload {
    key: String!
    expiresAt: DateTime!
}

input withdrawAndReplaceRedundantRateInput {
    contractID: String!
    replaceReason: String!
    withdrawnRateID: String!
    replacementRateID: String!
}

type withdrawAndReplaceRedundantRatePayload {
      contract: Contract!
}

"Date is a CalendarDate representing a day without time information"
scalar Date
"DateTime is a moment in time with date and time information"
scalar DateTime

union User = StateUser | CMSUser | AdminUser | HelpdeskUser | BusinessOwnerUser | CMSApproverUser

"StateUser is a user that works for a state, submitting packages to be reviewed by CMSUsers"
type StateUser {
    id: ID!
    "will always be 'STATE_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
    state: State!
}

"CMSUser is a user that works for CMS, reviewing packages submitted by StateUsers"
type CMSUser {
    id: ID!
    "will always be 'CMS_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
    stateAssignments: [State!]!
    divisionAssignment: Division
}

"CMSApproverUser is a user that works for CMS, approving CMS approver request and able to do everything a CMSUser can do"
type CMSApproverUser {
    id: ID!
    "will always be 'CMS_APPROVER_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
    stateAssignments: [State!]!
    divisionAssignment: Division
}

union CMSUsersUnion = CMSUser | CMSApproverUser

"AdminUser is a user that works on the MC Review app itself"
type AdminUser {
    id: ID!
    "will always be 'ADMIN_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
}

"HelpdeskUser is a user that supports state and cms users"
type HelpdeskUser {
    id: ID!
    "will always be 'HELPDESK_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
}

"The Business Owner is the root of our role hierarchy with the power to approve new admin accounts"
type BusinessOwnerUser {
    id: ID!
    "will always be 'BUSINESSOWNER_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
}

"State is a single US state or territory that operates managed care programs"
type State {
    "The state code (e.g. CA, TN)"
    code: String!
    name: String!
    "A list of the state's Managed Care programs"
    programs: [Program!]!
}

"Program represents a Managed Care program for the given state"
type Program {
    "uuid"
    id: ID!
    "A nickname for the program"
    name: String!
    "The full name for the program"
    fullName: String!
    "Specifies if a program relates to a rate rather than a contract"
    isRateProgram: Boolean!
}

"""
HealthPlanPackageStatus tracks the editing vs. submitted status of the package.
It is not intended to track the overall status of the package through the review process
as that is fleshed out.

State Machine:
```
DRAFT -> SUBMITTED -> UNLOCKED -> RESUBMITTED
                         ^               |
                         |---------------|
```

This status is used to determine whether the package is currently being edited by a state user
or if it has been submitted and is being reviewed by CMS users.
"""
enum HealthPlanPackageStatus {
    DRAFT
    SUBMITTED
    UNLOCKED
    RESUBMITTED
}

"""
This status is used to determine whether the package is currently being edited by a state user
or if it has been submitted and is being reviewed by CMS users.
"""
enum ContractReviewStatus {
    UNDER_REVIEW
    APPROVED
}

"""
This status is used to determine the review status of the rate.
"""
enum RateReviewStatus {
    UNDER_REVIEW
    WITHDRAWN
}

"""
This status has been synthesized to reflect if the status or review status
should be used
"""
enum ConsolidatedContractStatus {
    DRAFT
    SUBMITTED
    UNLOCKED
    RESUBMITTED
    APPROVED
}

"""
This status has been synthesized to reflect if the status or review status
should be used
"""
enum ConsolidatedRateStatus {
    DRAFT
    SUBMITTED
    UNLOCKED
    RESUBMITTED
    WITHDRAWN
}

enum UnlockedStatus {
    UNLOCKED
    DRAFT
}

enum ContractActionType {
    UNDER_REVIEW
    MARK_AS_APPROVED
}

enum RateActionType {
    UNDER_REVIEW
    WITHDRAW
}

"""
HealthPlanPackage is the core type for a single package submission. All the
submission data is contained in the HealthPlanRevision type, allowing us to store
the full history of packages previously submitted.

HealthPlanPackages are submitted by state users and reviewed by CMS users.
Initally created in DRAFT state, they are submitted using the submitHealthPlanPackage mutation.
CMS users are able to use the unlockHealthPlanPackage mutation to return it to the state user in
the UNLOCKED state for corrections. State users can then resubmit.
"""
type HealthPlanPackage {
    "uuid"
    id: ID!
    """
    Where the package is in the submission flow.
    SUBMITTED and RESUBMITTED packages cannot be modified
    """
    status: HealthPlanPackageStatus!
    "The initial date this package was submitted at. Is not changed by unlock or resubmission."
    initiallySubmittedAt: Date
    "The state code (e.g. CA or TN) for the submitting state"
    stateCode: String!
    "Fuller state data for the submitting state"
    state: State!
    "MC-CRS record number provided by CMS user (e.g. 1234)"
    mccrsID: String
    """
    Array of revisions for this package. Each revision represents a single submission
    for the package and contains the full data from when it was submitted
    """
    revisions: [HealthPlanRevisionEdge!]!
    questions: IndexContractQuestionsPayload
}

type UpdatedBy {
    role: String!
    email: String!
    givenName: String!
    familyName: String!
}

"""
UpdateInformation is used for the unlockInfo and the submitInfo on HealthPlanRevision.
It tracks who, when, and why the submission or unlock was performed.
"""
type UpdateInformation {
    "the datetime when the update occured"
    updatedAt: DateTime!
    "the email of the user who performed the update"
    updatedBy: UpdatedBy!
    "the reason provided by the user when performing the update"
    updatedReason: String!
}

"""
ContractReviewStatusActions is used for the review status actions on the contract
"""
type ContractReviewStatusActions {
    "the datetime when the update occurred"
    updatedAt: DateTime!
    "the user who performed the update"
    updatedBy: UpdatedBy!
    "the reason provided by the user when performing the update"
    updatedReason: String
    "the type of review action performed on the contract: APPROVED, WITHDRAWN"
    actionType: ContractActionType!
    "Manual time entered by CMS to represent when an approval released to state"
    dateApprovalReleasedToState: DateTime
    contractID: ID!
}

"""
RateReviewStatusActions is used for the review status actions on the rate
"""
type RateReviewStatusActions {
    "the datetime when the update occurred"
    updatedAt: DateTime!
    "the user who performed the update"
    updatedBy: UpdatedBy!
    "the reason provided by the user when performing the update"
    updatedReason: String!
    "type of action"
    actionType: RateActionType!
    rateID: ID!
}

"""
HealthPlanRevision is a single revision of the package. It contains all the
data from the form filled out by a state user about their package. When a
HealthPlanPackage is initially created, a single revision is created as well. That
revision has its submitInfo filled out when it is submitted, which is what marks
the HealthPlanPackage as SUBMITTED.

When a HealthPlanPackage is unlocked with the unlockHealthPlanPackage mutation
a new revision is created with a copy of the previous revision's formDataProto and its
unlockInfo filled out. This can then be modified by the state user and resubmitted.
"""
type HealthPlanRevision {
    id: ID!
    """
    Information about who, when, and why this revision was unlocked.
    Will be blank on the initial revision.
    """
    unlockInfo: UpdateInformation
    "Information on who, when, and why this revision was submitted."
    submitInfo: UpdateInformation
    createdAt: DateTime!
    """
    base64 encoded HealthPlanFormData protobuf. This contains all the data
    from the health plan pacakge form that the state user fills out and submits.
    Its schema can be found in health_plan_form_data.proto
    """
    formDataProto: String!
}

type HealthPlanRevisionEdge {
    node: HealthPlanRevision!
}

enum PopulationCoveredType {
    MEDICAID
    CHIP
    MEDICAID_AND_CHIP
}

enum SubmissionType {
    CONTRACT_ONLY
    CONTRACT_AND_RATES
}

enum ContractType {
    BASE
    AMENDMENT
}

"""
Document represents a document that has been uploaded to S3. It can be retrieved at the s3URL
by an authenticated user.
"""
type Document {
    name: String!
    s3URL: String!
    downloadURL: String
}

"""
ContractQuestion is a question sent by CMS to the States for a response, associated with a single contract.
CMS may upload one or more documents full of questions to a single ContractQuestion. States submit a
QuestionResponse with documents that answer the questions posed by CMS.
"""
type ContractQuestion {
    id: ID!
    contractID: ID!
    createdAt: DateTime!
    addedBy: CMSUsersUnion!
    documents: [Document!]!
    division: Division!
    round: Int!
    #    noteText: String
    #    dueDate: Date
    #    rateIDs: [String!]
    #
    responses: [QuestionResponse!]!
}

"""
RateQuestion is a question sent by CMS to the States for a response, associated with a single rate.
CMS may upload one or more documents full of questions to a single Question. States submit a
QuestionResponse with documents that answer the questions posed by CMS.
"""
type RateQuestion {
    id: ID!
    rateID: ID!
    createdAt: DateTime!
    addedBy: CMSUsersUnion!
    documents: [Document!]!
    division: Division!
    #    noteText: String
    #    dueDate: Date
    #    rateIDs: [String!]
    #
    responses: [QuestionResponse!]!
}

"""
QuestionResponse are responses to Rate or Contract questions, the graphql type has no differentiation between responses
for both questions types.
"""
type QuestionResponse {
    id: ID!
    questionID: ID!
    createdAt: DateTime!
    addedBy: StateUser!
    documents: [Document!]!
    #    noteText: String
}

"""
Email configurations for the application used in automated emails
"""
type EmailConfiguration {
    emailSource: String!
    devReviewTeamEmails: [String!]!
    oactEmails: [String!]!
    dmcpReviewEmails: [String!]!
    dmcpSubmissionEmails: [String!]!
    dmcoEmails: [String!]!
    cmsReviewHelpEmailAddress: String!
    cmsRateHelpEmailAddress: String!
    helpDeskEmail: String!
}

input EmailConfigurationInput {
    emailSource: String!
    devReviewTeamEmails: [String!]!
    oactEmails: [String!]!
    dmcpReviewEmails: [String!]!
    dmcpSubmissionEmails: [String!]!
    dmcoEmails: [String!]!
    cmsReviewHelpEmailAddress: String!
    cmsRateHelpEmailAddress: String!
    helpDeskEmail: String!
}

input UpdateEmailSettingsInput {
    emailConfiguration: EmailConfigurationInput!
}

type UpdateEmailSettingsPayload {
    emailConfiguration: EmailConfiguration!
}

"""
GenericDocument

This document type should be used (or extended) everywhere we pass documents through GraphQL regardless of domain
"""
type GenericDocument {
    "The user created name of the document"
    name: String!
    "The S3 URL of the document, generated on the FE currently in the FileUpload component"
    s3URL: String!
    "The sha256 is a unique string representing the file, generated on the FE currently in the FileUpload component"
    sha256: String!
    "URL received from S3 for downloading the document"
    downloadURL: String
    "The first date this document was added to submitted package - if still an initial draft, this date is last updated, otherwise it is the submitInfo lastUpdated"
    dateAdded: DateTime
}

"""
GenericDocument

This document input should be used (or extended) everywhere we pass documents through GraphQL regardless of domain
"""
input GenericDocumentInput {
    "The user created name of the document"
    name: String!
    "The S3 URL of the document, generated on the FE currently in the FileUpload component"
    s3URL: String!
    "The sha256 is a unique string representing the file, generated on the FE currently in the FileUpload component"
    sha256: String!
    "The url used for downloading a file from S3"
    downloadURL: String
     "The first date this document was added to submitted package - this is ignored on input and regenerated on return"
    dateAdded: DateTime
}

"The large overarching population of people that the program covers."
enum PopulationCovered {
    MEDICAID
    CHIP
    MEDICAID_AND_CHIP
}
"Whether contract has been fully executed by all parties or not"
enum ContractExecutionStatus {
    EXECUTED
    UNEXECUTED
}

"""
The type of organization the state is contracting with in order to deliver
managed care services
"""
enum ManagedCareEntity {
    MCO
    PIHP
    PAHP
    PCCM
}

"""
The state plan and/or waiver authorities that allow the
state to run its managed care programs
"""
enum FederalAuthority {
    STATE_PLAN
    WAIVER_1915B
    WAIVER_1115
    VOLUNTARY
    BENCHMARK
    TITLE_XXI
}
"Contact information for contacting states regarding their submission"
type StateContact {
    name: String
    titleRole: String
    email: String
}

"""
ContractFormData represents the form data that was inputted by the state
This type is used for the form data field found on a contract revision
"""
type ContractFormData {
    """
    An array of IDs representing state programs that the contract covers
    """
    programIDs: [String!]!
    """
    The large overarching population of people that the program covers.
    Options are MEDICAID, CHIP, MEDICAID_AND_CHIP
    """
    populationCovered: PopulationCovered
    """
    The submission type of this package
    Options are CONTRACT_ONLY and CONTRACT_AND_RATES
    """
    submissionType: SubmissionType!
    """
    Whether or not this contract is risk based
    Risk-based contracts have specific requirements that
    non-risk based contracts do not have
    """
    riskBasedContract: Boolean
    "State provided summary of the contract being submitted"
    submissionDescription: String!
    """
    Array of state contacts of state representatives who should be
    contacted about updates to the contract
    Each state contact contains string fields for: name, title, and email
    """
    stateContacts: [StateContact!]!
    """
    Additional documents the state uploads to support a contract
    Files can be PDF, DOC, DOCX, XLSX, CSV format
    """
    supportingDocuments: [GenericDocument!]!
    """
    Type of contract the state is submitting
    Options are: BASE, AMENDMENT
    """
    contractType: ContractType
    """
    Execution status for a contract.
    Contracts are fully executed or unexecuted by some or all parties
    Status can be either EXECUTED or UNEXECUTED
    """
    contractExecutionStatus: ContractExecutionStatus
    """
    State upload of the submitted contract
    """
    contractDocuments: [GenericDocument!]!
    "Start date of the contract"
    contractDateStart: Date
    "End date of the contract"
    contractDateEnd: Date
    """
    The type of organization the state is contracting with
    in order to deliver managed care services
    Options are MCO, PIHP, PAHP, and PCCM
    """
    managedCareEntities: [ManagedCareEntity!]!
    """
    The state plan and/or waiver authorities that allow the state
    to run its managed care programs
    """
    federalAuthorities: [FederalAuthority!]!
    """
    If contract is in Lieu-of Services and Settings (ILOSs)
    in accordance with 42 CFR § 438.3(e)(2)
    """
    inLieuServicesAndSettings: Boolean
    "If contract includes modifications to benefits provided by the managed care plans"
    modifiedBenefitsProvided: Boolean
    "If contract includes modifications to the geographic areas served by the managed care plans"
    modifiedGeoAreaServed: Boolean
    """
    If contract includes modifications to the Medicaid beneficiaries served by the managed care
    plans (e.g. eligibility or enrollment criteria)
    """
    modifiedMedicaidBeneficiaries: Boolean
    "If contract includes modifications to the risk sharing strategy"
    modifiedRiskSharingStrategy: Boolean
    "If contract includes modifications to incentive arrangements"
    modifiedIncentiveArrangements: Boolean
    "If contract includes modifications to the withold agreements"
    modifiedWitholdAgreements: Boolean
    "If contract includes modifications to the state directed payments"
    modifiedStateDirectedPayments: Boolean
    "If contract includes modifications to the pass-through payments"
    modifiedPassThroughPayments: Boolean
    """
    If contract includes modifications to payments to MCOs and PIHPs for enrollees that
    are a patient in an institution for mental disease
    """
    modifiedPaymentsForMentalDiseaseInstitutions: Boolean
    "If contract includes modifications to the medical loss ratio standards"
    modifiedMedicalLossRatioStandards: Boolean
    """
    If contract includes modifications to
    other financial, payment, incentive or related contractual provisions
    """
    modifiedOtherFinancialPaymentIncentive: Boolean
    "If contract includes modifications to the enrollment/disenrollment process"
    modifiedEnrollmentProcess: Boolean
    "If contract includes modifications to the grevience and appeal system"
    modifiedGrevienceAndAppeal: Boolean
    "If contract includes modifications to the network adequacy standards"
    modifiedNetworkAdequacyStandards: Boolean
    "If contract includes modifications to the length of the contract period"
    modifiedLengthOfContract: Boolean
    "If contract includes modifications to the non-risk payment arrangements"
    modifiedNonRiskPaymentArrangements: Boolean,
    "If contract has statutory regulatory attestation"
    statutoryRegulatoryAttestation: Boolean,
    "Description provided for if contract has statutory regulatory attestation"
    statutoryRegulatoryAttestationDescription: String
}

"Either new capitation rates (NEW) or updates to previously certified capitation rates (AMENDMENT)"
enum RateAmendmentType {
    NEW
    AMENDMENT
}

"""
Determines on what basis the capitation rate is actuarially sound.
With RATE_RANGE the state certifies a range of rates
from the low to high end of the range as actuarially sound
"""
enum RateCapitationType {
    RATE_CELL
    RATE_RANGE
}

"""
State's communication preference for contacting their actuaries. Either:
- wants CMS to reach out to their actuaries directly or
- go through them
"""
enum ActuaryCommunication {
    OACT_TO_ACTUARY
    OACT_TO_STATE
}

"The firm that the certifying actuary works for"
enum ActuarialFirm {
    MERCER
    MILLIMAN
    OPTUMAS
    GUIDEHOUSE
    DELOITTE
    STATE_IN_HOUSE
    OTHER
}

"Contact information for the certifying or additional state actuary"
type ActuaryContact {
    id: ID
    name: String
    titleRole: String
    email: String
    actuarialFirm: ActuarialFirm
    actuarialFirmOther: String
}

"Contact information input for the certifying or additional state actuary"
input ActuaryContactInput {
    id: ID
    name: String
    titleRole: String
    email: String
    actuarialFirm: ActuarialFirm
    actuarialFirmOther: String
}

"Contact information input for contacting states regarding their submission"
input StateContactInput {
    name: String
    titleRole: String
    email: String
}


"""
A package in the system
that shares a rate with another package.
It's used as a part of RateFormData
"""
type PackageWithSameRate {
    packageName: String!
    packageId: String!
    packageStatus: HealthPlanPackageStatus
}

"""
RateFormData represents the form data that was inputted by the state
This type is used for the form data field found on a rate revision
"""
type RateFormData {
    """
    Can be 'NEW' or 'AMENDMENT'
    Refers to whether the state is submitting a brand new rate certification
    or an amendment to an existing rate certification
    """
    rateType: RateAmendmentType
    """
    Can be 'RATE_CELL' or 'RATE_RANGE'
    These values represent on what basis the capitation rate is actuarially sound
    """
    rateCapitationType: RateCapitationType
    """
    Signed certification documents the state uploads
    Files can be PDF, DOC, or DOCX format
    """
    rateDocuments: [GenericDocument!]!
    """
    Additional documents the state uploads to support a rate cert
    Files can be PDF, DOC, DOCX, XLSX, CSV format
    """
    supportingDocuments: [GenericDocument!]!
    """
    If the rateType is NEW this is the start date of the
    rating period for a new certification.
    If the rateType is AMENDMENT this is the start date of the
    rating period for the original rate certification
    """
    rateDateStart: Date
    """
    If the rateType is NEW this is the end date of the
    rating period for a new certification.
    If the rateType is AMENDMENT this is the end date of the
    rating period for the original rate certification
    """
    rateDateEnd: Date
    """
    The date the rate certification was
    certified/signed by the state's actuary
    """
    rateDateCertified: Date
    """
    The start date of the rate amendment
    Only relevant if rate type is AMENDMENT
    """
    amendmentEffectiveDateStart: Date
    """
    The end date of the rate amendment
    Only relevant if rate type is AMENDMENT
    """
    amendmentEffectiveDateEnd: Date
    """
    Deprecated an array of IDs representing historic state programs that the rate covers
    """
    deprecatedRateProgramIDs: [String!]!
    """
    An array of IDs representing historic state programs that the rate covers
    """
    rateProgramIDs: [String!]!
    """
    consolidatedRateProgramIDs An array of IDs representing state programs. Deprecated if that's all that exists, or the current ones if they exist.
    """
    consolidatedRateProgramIDs: [String!]!
    """
    Represents the name of the rate.
    This value is auto generated based on rate, package and state program details
    """
    rateCertificationName: String
    """
    An array of ActuaryContacts
    Each element includes the the name, title/role and email
    of the actuaries who certified the rate
    """
    certifyingActuaryContacts: [ActuaryContact!]!
    """
    An array of additional ActuaryContacts
    Each element includes the the name, title/role and email
    """
    addtlActuaryContacts: [ActuaryContact!]!
    """
    Is either OACT_TO_ACTUARY or OACT_TO_STATE
    It specifies whether the state wants CMS to reach out to their actuaries
    directly or go through them
    """
    actuaryCommunicationPreference: ActuaryCommunication
    """
    An array of PackageWithSameRate elements
    which contain the packageName, packageId, and packageStatus
    These elements represent other packages in the system
    that are using this rate
    """
    packagesWithSharedRateCerts: [PackageWithSameRate!]!
}

"UpdateContractRateType describes the relationship of the updated rate to this contract"
enum UpdateContractRateType {
    "CREATE is going to create a new child rate, never seen before. Future updates of this rate will call UPDATE"
    CREATE
    "UPDATE updates the form data for a given child rate, whether previously created for this submission or unlocked"
    UPDATE
    "LINK describes a linked nibling to this contract rate"
    LINK
}

"""
UpdateContractRateInput is a swiss army knife for updating rates related to a given contract.
Because GQL does not support unions in inputs, we will fake one here by setting the type parameter
on this input and the API will validate that the two optional parameters are set correctly given the type.
"""
input UpdateContractRateInput {
    "type indicates the relationship of this rate to the contract"
    type: UpdateContractRateType!
    "rateID is the rateID of the existing rate. This is REQUIRED for LINK and UPDATE types and OMITTED for CREATE"
    rateID: ID
    "formData is the rate data for a child rate, REQUIRED for CREATE and UPDATE types, omitted for LINK"
    formData: RateFormDataInput
}

input UpdateDraftContractRatesInput {
    contractID: ID!
    "The updatedAt of the contract draft revision at the time this mutation. This is used for optimistic concurrency control"
    lastSeenUpdatedAt: DateTime!
    """
    updatedRates contains ALL the existing rates associated with this contract.
    This includes rates that were previously linked or previously created (LINK & UPDATE)
    as well as newly created rates and newly linked rates (CREATE & LINK)
    any rates that are no longer associated with this contract should be omitted from the list.
    """
    updatedRates: [UpdateContractRateInput!]!
}

type UpdateDraftContractRatesPayload {
    contract: Contract!
}

"""
A rate revision represents a single submission
for the rate and contains the full data from when the rate cert was submitted
"""
type RateRevision {
    id: ID!
    rate: Rate
    rateID: String!
    createdAt: DateTime!
    updatedAt: DateTime!
    """
    Information about who, when, and why this revision was unlocked.
    Will be blank on the initial revision.
    """
    unlockInfo: UpdateInformation
    "Information on who, when, and why this revision was submitted."
    submitInfo: UpdateInformation
    "The rate related form data that was inputed by the state"
    formData: RateFormData!
}

"""
Rates are rate certifications and their associated actuary contacts and documents
State users may create, update, and submit several rates at a time
"""
type Rate {
    id: ID!
    createdAt: DateTime!
    updatedAt: DateTime!
    """
    Where the package is in the submission flow.
    Options are DRAFT, SUBMITTED, RESUBMITTED and UNLOCKED
    SUBMITTED and RESUBMITTED packages cannot be modified
    """
    status: HealthPlanPackageStatus!
    """
    Where the rate is in the review submission flow
    Options are UNDER_REVIEW, WITHDRAWN
    """
    reviewStatus: RateReviewStatus!
    """
    Synthesized field that represents if a rate's status or
    reviewStatus should take precedence
    Options are DRAFT, SUBMITTED, RESUBMITTED UNLOCKED, UNDER_REVIEW, WITHDRAWN
    """
    consolidatedStatus: ConsolidatedRateStatus!
    "The initial date this rate was submitted at. Is not changed by unlock or resubmission."
    initiallySubmittedAt: DateTime
    "The state code (e.g. CA or TN) for the submitting state"
    stateCode: String!
    "Fuller state data for the submitting state"
    state: State!
    "webURL is a URL that will open the MC Review web app to this Rate"
    webURL: String!

    """
    A unique auto-incrementing number generated for this rate
    This value is used to generate the rateName
    """
    stateNumber: Int!
    "parentContractID is the ID of the contract that initially submitted this rate"
    parentContractID: ID!
    "withdrawInfo is the who/when/why for this rate being withdrawn. Rates actively in use in the system will not have a withdrawInfo"
    withdrawInfo: UpdateInformation

    "Contracts this withdrawn rates was the child or linked to"
    withdrawnFromContracts: [Contract!]

    "The currently modifiable revision of the rate. Only present when rate has a status of DRAFT or UNLOCKED"
    draftRevision: RateRevision

    "reviewStatusActions are the who/when/why for review status changes on this contract"
    reviewStatusActions: [RateReviewStatusActions!]

    """
    Array of revisions for this rate. Each revision represents a single submission
    for the rate and contains the full data from when the rate cert was submitted
    """
    revisions: [RateRevision!]!

    """
    packageSubmissions are a list of all the submissions of this rate and any contracts it
    was associated with.
    """
    packageSubmissions: [RatePackageSubmission!]
    """
    questions field is an array of questions asked about the rate by CMS. Each questions also contains responses
    to the question submitted by the State. DRAFT rates will not have questions, only rates that have been submitted
    , unlocked, or resubmitted. The array is in descending order by createdAt.
    """
    questions: IndexRateQuestionsPayload
}

"""
ContractRevision is a single instance of all the contract specific data in a submission
it contains the unlock and submit info.
"""
type ContractRevision {
    id: ID!
    contractID: String!

    createdAt: DateTime!
    updatedAt: DateTime!
    "submitInfo is the who/when/why for this revision being submitted. An Unlocked revision has no submitInfo"
    submitInfo: UpdateInformation
    "unlockInfo is the who/when/why for this revision being unlocked. A DRAFT or SUBMITTED revision will not have one"
    unlockInfo: UpdateInformation

    "formData is all the contract specific info part of this submission"
    formData: ContractFormData!
    """
    Represents the name of the contract pacakge.
    This value is auto generated based on contract state number, contract type, dates, and state program details"\
    """
    contractName: String!
}

"SubmittableRevision is what can appear in a submission"
union SubmittableRevision = ContractRevision | RateRevision

"SubmissionReason is a hint as to why this PackageSubmission was created"
enum SubmissionReason {
    "A submission of this contract and zero or more newly created rates"
    CONTRACT_SUBMISSION
    "A submission of one of the rates related to this contract"
    RATE_SUBMISSION
    "A submission of a rate related to this contract, removing the link between the two"
    RATE_UNLINK
    "A submission of an unrelated rate, linking it to this contract"
    RATE_LINK
}

"""
ContractPackageSubmission is a snapshot of a contract and all its related rates in time
a ContractPackageSubmission can be created by the user submitting a contract or a related rate
"""
type ContractPackageSubmission {
    "cause is a hint as to why this submission was created"
    cause: SubmissionReason!
    "submitInfo provides the submission reason/date/by for this package submission"
    submitInfo: UpdateInformation!
    "submittedRevisions is a list of contract and/or rate revisions that were submitted to create this package submission"
    submittedRevisions: [SubmittableRevision!]!
    "contractRevision is the contract revision current at the time of this package submission"
    contractRevision: ContractRevision!
    "rateRevisions are the linked rate revisions current at the time of this package submission"
    rateRevisions: [RateRevision!]!
}

"""
RatePackageSubmission is a snapshot of a contract and all its related rates in time
a RatePackageSubmission can be created by the user submitting a contract or a related rate
"""
type RatePackageSubmission {
    "cause is a hint as to why this submission was created"
    cause: SubmissionReason!
    "submitInfo provides the submission reason/date/by for this package submission"
    submitInfo: UpdateInformation!
    "submittedRevisions is a list of contract and/or rate revisions that were submitted to create this package submission"
    submittedRevisions: [SubmittableRevision!]!
    "rateRevision are the linked rate revisions current at the time of this package submission"
    rateRevision: RateRevision!
    "contractRevision is the contract revision current at the time of this package submission"
    contractRevisions: [ContractRevision!]!
}

"""
Contract is a single contract submission, holding all the form data for a single contract
and associated with zero or more Rates
"""
type Contract {
    id: ID!
    createdAt: DateTime!
    updatedAt: DateTime!

    """
    Where the contract is in the submission flow.
    Options are DRAFT, SUBMITTED, RESUBMITTED and UNLOCKED
    SUBMITTED and RESUBMITTED packages cannot be modified
    """
    status: HealthPlanPackageStatus!
    """
    Where the contract is in the review submission flow
    Options are UNDER_REVIEW, APPROVED
    """
    reviewStatus: ContractReviewStatus!
    """
    Synthesized field that represents if a contract's status or
    reviewStatus should take precedence 
    Options are DRAFT, SUBMITTED, RESUBMITTED UNLOCKED, UNDER_REVIEW, APPROVED
    """
    consolidatedStatus: ConsolidatedContractStatus!
    "initiallySubmittedAt is the initial date this contract was submitted at. Is not changed by unlock or resubmission."
    initiallySubmittedAt: DateTime
    "lastUpdatedForDisplay is the last time this contract was officially updated. When it's a draft it will be the last updatedAt date. Afterwards it will be the most recent submit or unlock or review action date."
    lastUpdatedForDisplay: DateTime!
    "stateCode is the state code (e.g. CA or TN) for the submitting state"
    stateCode: String!
    "state is fuller state data for the submitting state"
    state: State!
    """
    stateNumber is a unique auto-incrementing number identifying this contract
    This value is used to generate the contractName
    """
    stateNumber: Int!
    "mccrsID is the four digit id in MC-CRS that corresponds to this Contract, if set"
    mccrsID: String

    "webURL is a url that will open the MC Review web app to this Contract"
    webURL: String!

    "dateContractDocsExecuted is the first date, if any in which the revisions were submitted with all contract docs executed. Null otherwise."
    dateContractDocsExecuted: DateTime

    "draftRevision is the currently modifiable revision if the rate is DRAFT or UNLOCKED"
    draftRevision: ContractRevision
    """
    draftRates are the Rates that this editable submission are related to.
    On submission they are cemented into a new ContractPackageSubmission
    """
    draftRates: [Rate!]

    "reviewStatusActions are the who/when/why for review status changes on this contract"
    reviewStatusActions: [ContractReviewStatusActions!]

    "Rates withdrawn from this contract submission"
    withdrawnRates: [Rate!]

    """
    packageSubmissions are a snapshot of the contract and its related rates through time
    each packageSubmission was created by a submission of this contract and/or its related rates
    a DRAFT Contract will have no packageSubmissions. Returned in _ascending_ order. Most recent
    submission is in the first position in the array.
    """
    packageSubmissions: [ContractPackageSubmission!]!

    """
    questions field is an array of questions asked about the contract by CMS. Each questions also contains responses
    to the question submitted by the State. DRAFT contracts will not have questions, only contracts that have been submitted
    , unlocked, or resubmitted. The array is in descending order by createdAt.
    """
    questions: IndexContractQuestionsPayload
}

"""
UnlockedContract is a single unlocked contract, holding all the draft form data for a single contract
and associated with zero or more Rates
"""
type UnlockedContract {
    id: ID!
    createdAt: DateTime!
    updatedAt: DateTime!

    """
    Where the contract is in the submission flow.
    Options are DRAFT, SUBMITTED, RESUBMITTED and UNLOCKED
    SUBMITTED and RESUBMITTED packages cannot be modified
    """
    status: UnlockedStatus!
     """
    Where the contract is in the review submission flow
    Options are UNDER_REVIEW, APPROVED
    """
    reviewStatus: ContractReviewStatus!
    """
    Synthesized field that represents if a contract's status or
    reviewStatus should take precedence 
    Options are DRAFT, SUBMITTED, RESUBMITTED UNLOCKED, UNDER_REVIEW, APPROVED
    """
    consolidatedStatus: ConsolidatedContractStatus!
    "initiallySubmittedAt is the initial date this contract was submitted at. Is not changed by unlock or resubmission."
    initiallySubmittedAt: DateTime
    "lastUpdatedForDisplay is the last time this contract was officially updated. When it's a draft it will be the last updatedAt date. Afterwards it will be the most recent submit or unlock or review action date."
    lastUpdatedForDisplay: DateTime!
    "stateCode is the state code (e.g. CA or TN) for the submitting state"
    stateCode: String!
    "state is fuller state data for the submitting state"
    state: State!
    """
    stateNumber is a unique auto-incrementing number identifying this contract
    This value is used to generate the contractName
    """
    stateNumber: Int!
    "mccrsID is the four digit id in MC-CRS that corresponds to this Contract, if set"
    mccrsID: String

    "webURL is a url that will open the MC Review web app to this Contract"
    webURL: String!

    "dateContractDocsExecuted is the first date, if any in which the revisions were submitted with all contract docs executed. Null otherwise."
    dateContractDocsExecuted: DateTime

    "reviewStatusActions are the who/when/why for review status changes on this contract"
    reviewStatusActions: [ContractReviewStatusActions!]

    "draftRevision is the currently modifiable revision if the rate is DRAFT or UNLOCKED"
    draftRevision: ContractRevision!
    """
    draftRates are the Rates that this editable submission are related to.
    On submission they are cemented into a new ContractPackageSubmission
    """
    draftRates: [Rate!]!

    "Rates withdrawn from this contract submission"
    withdrawnRates: [Rate!]

    """
    packageSubmissions are a snapshot of the contract and its related rates through time
    each packageSubmission was created by a submission of this contract and/or its related rates
    a DRAFT Contract will have no packageSubmissions. Returned in _ascending_ order. Most recent
    submission is in the first position in the array.
    """
    packageSubmissions: [ContractPackageSubmission!]!

    """
    questions field is an array of questions asked about the contract by CMS. Each questions also contains responses
    to the question submitted by the State. DRAFT contracts will not have questions, only contracts that have been submitted
    , unlocked, or resubmitted. The array is in descending order by createdAt.
    """
    questions: IndexContractQuestionsPayload
}

type RateEdge {
    node: Rate!
}

input IndexRatesInput {
    stateCode: String
}

type IndexRatesPayload {
    "Total number of submitted rates returned on request"
    totalCount: Int
    """
    Rates that include rate and contract revisions
    """
    edges: [RateEdge!]!
}

type FetchRatePayload {
    """
    A rate that include contract and rate revisions
    """
    rate: Rate!
}

input FetchRateInput {
    rateID: ID!
}

type FetchContractPayload {
    """
    A rate that include contract and rate revisions
    """
    contract: Contract!
}

input FetchContractInput {
    contractID: ID!
}

type UnlockRatePayload {
    rate: Rate!
}

type SubmitRatePayload {
    rate: Rate!
}

input UnlockRateInput {
    rateID: ID!
    "User given reason this rate was unlocked"
    unlockedReason: String!
}

type StateAssignmentUser {
    id: ID!
    "will be 'CMS_USER' or 'CMS_APPROVER_USER'"
    role: String!
    email: String!
    givenName: String!
    familyName: String!
    divisionAssignment: Division
}

type StateAssignment {
    stateCode: String!
    name: String!
    assignedCMSUsers: [CMSUsersUnion!]!
}

type FetchMcReviewSettingsPayload {
    emailConfiguration: EmailConfiguration
    stateAssignments: [StateAssignment!]!
}

input ContractDraftRevisionFormDataInput {
    """
    An array of IDs representing state programs that the contract covers
    """
    programIDs: [String!]!
    """
    The large overarching population of people that the program covers.
    Options are MEDICAID, CHIP, MEDICAID_AND_CHIP
    """
    populationCovered: PopulationCovered
    """
    The submission type of this package
    Options are CONTRACT_ONLY and CONTRACT_AND_RATES
    """
    submissionType: SubmissionType
    """
    Whether or not this contract is risk based
    Risk-based contracts have specific requirements that
    non-risk based contracts do not have
    """
    riskBasedContract: Boolean
    "State provided summary of the contract being submitted"
    submissionDescription: String
    """
    Array of state contacts of state representatives who should be
    contacted about updates to the contract
    Each state contact contains string fields for: name, title, and email
    """
    stateContacts: [StateContactInput!]!
    """
    Additional documents the state uploads to support a contract
    Files can be PDF, DOC, DOCX, XLSX, CSV format
    """
    supportingDocuments: [GenericDocumentInput!]!
    """
    Type of contract the state is submitting
    Options are: BASE, AMENDMENT
    """
    contractType: ContractType
    """
    Execution status for a contract.
    Contracts are fully executed or unexecuted by some or all parties
    Status can be either EXECUTED or UNEXECUTED
    """
    contractExecutionStatus: ContractExecutionStatus
    """
    State upload of the submitted contract
    """
    contractDocuments: [GenericDocumentInput!]!
    "Start date of the contract"
    contractDateStart: Date
    "End date of the contract"
    contractDateEnd: Date
    """
    The type of organization the state is contracting with
    in order to deliver managed care services
    Options are MCO, PIHP, PAHP, and PCCM
    """
    managedCareEntities: [ManagedCareEntity!]!
    """
    The state plan and/or waiver authorities that allow the state
    to run its managed care programs
    """
    federalAuthorities: [FederalAuthority!]!
    """
    If contract is in Lieu-of Services and Settings (ILOSs)
    in accordance with 42 CFR § 438.3(e)(2)
    """
    inLieuServicesAndSettings: Boolean
    "If contract includes modifications to benefits provided by the managed care plans"
    modifiedBenefitsProvided: Boolean
    "If contract includes modifications to the geographic areas served by the managed care plans"
    modifiedGeoAreaServed: Boolean
    """
    If contract includes modifications to the Medicaid beneficiaries served by the managed care
    plans (e.g. eligibility or enrollment criteria)
    """
    modifiedMedicaidBeneficiaries: Boolean
    "If contract includes modifications to the risk sharing strategy"
    modifiedRiskSharingStrategy: Boolean
    "If contract includes modifications to incentive arrangements"
    modifiedIncentiveArrangements: Boolean
    "If contract includes modifications to the withold agreements"
    modifiedWitholdAgreements: Boolean
    "If contract includes modifications to the state directed payments"
    modifiedStateDirectedPayments: Boolean
    "If contract includes modifications to the pass-through payments"
    modifiedPassThroughPayments: Boolean
    """
    If contract includes modifications to payments to MCOs and PIHPs for enrollees that
    are a patient in an institution for mental disease
    """
    modifiedPaymentsForMentalDiseaseInstitutions: Boolean
    "If contract includes modifications to the medical loss ratio standards"
    modifiedMedicalLossRatioStandards: Boolean
    """
    If contract includes modifications to
    other financial, payment, incentive or related contractual provisions
    """
    modifiedOtherFinancialPaymentIncentive: Boolean
    "If contract includes modifications to the enrollment/disenrollment process"
    modifiedEnrollmentProcess: Boolean
    "If contract includes modifications to the grevience and appeal system"
    modifiedGrevienceAndAppeal: Boolean
    "If contract includes modifications to the network adequacy standards"
    modifiedNetworkAdequacyStandards: Boolean
    "If contract includes modifications to the length of the contract period"
    modifiedLengthOfContract: Boolean
    "If contract includes modifications to the non-risk payment arrangements"
    modifiedNonRiskPaymentArrangements: Boolean,
    "If contract has statutory regulatory attestation"
    statutoryRegulatoryAttestation: Boolean,
    "Description provided for if contract has statutory regulatory attestation"
    statutoryRegulatoryAttestationDescription: String
}

input RateFormDataInput {
    """
    Can be 'NEW' or 'AMENDMENT'
    Refers to whether the state is submitting a brand new rate certification
    or an amendment to an existing rate certification
    """
    rateType: RateAmendmentType
    """
    Can be 'RATE_CELL' or 'RATE_RANGE'
    These values represent on what basis the capitation rate is actuarially sound
    """
    rateCapitationType: RateCapitationType
    """
    Signed certification documents the state uploads
    Files can be PDF, DOC, or DOCX format
    """
    rateDocuments: [GenericDocumentInput!]!
    """
    Additional documents the state uploads to support a rate cert
    Files can be PDF, DOC, DOCX, XLSX, CSV format
    """
    supportingDocuments: [GenericDocumentInput!]!
    """
    If the rateType is NEW this is the start date of the
    rating period for a new certification.
    If the rateType is AMENDMENT this is the start date of the
    rating period for the original rate certification
    """
    rateDateStart: Date
    """
    If the rateType is NEW this is the end date of the
    rating period for a new certification.
    If the rateType is AMENDMENT this is the end date of the
    rating period for the original rate certification
    """
    rateDateEnd: Date
    """
    The date the rate certification was
    certified/signed by the state's actuary
    """
    rateDateCertified: Date
    """
    The start date of the rate amendment
    Only relevant if rate type is AMENDMENT
    """
    amendmentEffectiveDateStart: Date
    """
    The end date of the rate amendment
    Only relevant if rate type is AMENDMENT
    """
    amendmentEffectiveDateEnd: Date
    """
    Deprecated an array of IDs representing historic state programs that the rate covers
    """
    deprecatedRateProgramIDs: [String!]!
    """
    An array of IDs representing historic state programs that the rate covers
    """
    rateProgramIDs: [String!]!
    """
    Represents the name of the rate.
    This value is auto generated based on rate, package and state program details
    """
    rateCertificationName: String
    """
    An array of ActuaryContacts
    Each element includes the the name, title/role and email
    of the actuaries who certified the rate
    """
    certifyingActuaryContacts: [ActuaryContactInput!]!
    """
    An array of additional ActuaryContacts
    Each element includes the the name, title/role and email
    """
    addtlActuaryContacts: [ActuaryContactInput!]
    """
    Is either OACT_TO_ACTUARY or OACT_TO_STATE
    It specifies whether the state wants CMS to reach out to their actuaries
    directly or go through them
    """
    actuaryCommunicationPreference: ActuaryCommunication
}

input SubmitRateInput {
    rateID: ID!
    "User given submission description"
    submittedReason: String
    "Rate related form data to be updated with submission"
    formData: RateFormDataInput
}
